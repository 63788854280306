import cx from 'classnames';
import React from 'react';

import * as styles from './Pill.module.css';

const Pill = ({
	children,
	reverse,
	as: Component = 'div',
	className = '',
}: {
	children: React.ReactNode;
	reverse?: boolean;
	as?: React.ElementType;
	className?: string;
}) => {
	return (
		<Component
			className={cx(styles.pill, {
				[styles.pillReverse]: reverse,
				[className]: !!className,
			})}>
			{children}
		</Component>
	);
};

export default Pill;
