import { LINKS } from '@utils/constants';
import { constructGroupSlug, constructProductSlug } from '@utils/helpers';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import { useFlexSearch } from 'react-use-flexsearch';

import Content from '@components/Layout/Content/Content';
import Grid from '@components/Layout/Grid/Grid';
import PageSection from '@components/Layout/PageSection/PageSection';
import CategoryListing from '@components/Listing/CategoryListing';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import Spinner from '@components/Molecule/Spinner/Spinner';
import StripCard from '@components/Molecule/StripCard/StripCard';
import Pill from '@components/Pill/Pill';
import ProductSearch from '@components/Search/ProductSearch';
import Copy from '@components/Typography/Copy';
import H from '@components/Typography/H';

const ProductSearchPage = ({ data }: { data: any }) => {
	const isBrowser = typeof window !== 'undefined';
	if (!isBrowser) return null;

	const [loading, setLoading] = useState(true);

	const { index, store } = data.localSearchProducts;

	const { search } = window.location;
	const query = new URLSearchParams(search).get('s');
	const results = useFlexSearch(query, index, store);

	const initialResults = [
		data.products.edges,
		data.groups.edges,
		data.categories.edges,
	].flat();

	const queriedResults = query ? results : initialResults;
	const groups = data.groups;
	const categories = data.categories;

	useEffect(() => {
		setLoading(false);

		return () => {
			setLoading(true);
		};
	}, [queriedResults]);

	const fallbackImage = data.fallbackImage;

	return (
		<Page>
			<SEO title="Product search results | UHV Design" />

			<PageSection>
				<div>
					<H level={2} as="h1">
						Product search results
					</H>
				</div>

				<ProductSearch type="alternate" />
			</PageSection>

			{loading ? (
				<PageSection background="grey">
					{query ? (
						<Copy font="xl2" weight="bold">
							Searching for results on '{query}'
						</Copy>
					) : null}

					<Spinner />
				</PageSection>
			) : queriedResults.length > 0 ? (
				<PageSection background="grey">
					{query ? (
						<Copy font="xl2" weight="bold">
							{queriedResults.length} results found for '{query}'
						</Copy>
					) : null}

					<Grid>
						{queriedResults.map((item, key) => {
							const data = item.node ? item.node.data : item;
							const { title, summary, product_image, image } =
								data;
							const type = item.node ? item.node.type : item.type;

							let slug;

							if (type === 'product')
								slug = constructProductSlug(
									item.node ? item.node : item,
									groups
								);

							if (type === 'product_group')
								slug = constructGroupSlug(
									item.node ? item.node : item,
									categories
								);

							if (type === 'product_category')
								slug = `${LINKS.PRODUCTS}/${
									item.node ? item.node.uid : item.uid
								}`;

							let imageToUse = null;

							if (product_image) imageToUse = product_image;
							if (image) imageToUse = image;

							return (
								<StripCard
									to={slug ? slug : '/'}
									title={title}
									thumbnail={getImage(
										imageToUse?.gatsbyImageData
											? imageToUse
											: fallbackImage.childImageSharp
													.gatsbyImageData
									)}
									key={key}>
									<Content spacing="sm">
										<H level={3}>{title}</H>
										{summary?.html && (
											<div
												dangerouslySetInnerHTML={{
													__html: summary.html,
												}}></div>
										)}
									</Content>

									<Pill
										reverse={
											type === 'product_group' ||
											type === 'product_category'
										}>
										<Copy weight="bold" font="xs">
											{type === 'product_group' ||
											type === 'product_category'
												? 'Category'
												: 'Product'}
										</Copy>
									</Pill>
								</StripCard>
							);
						})}
					</Grid>
				</PageSection>
			) : (
				<>
					<PageSection background="grey">
						<Content spacing="sm">
							<Copy font="xl2" weight="bold">
								No results found for '{query}'
							</Copy>
							<Copy>
								Why not try selecting a category below to find
								what you are looking for
							</Copy>
						</Content>
					</PageSection>

					<PageSection>
						<CategoryListing />
					</PageSection>

					<PageSection background="blue">
						<Copy
							weight="bold"
							font="xl2"
							align="center"
							prose={true}>
							If you would like to discuss your project with our
							engineering team, please{' '}
							<Link to={LINKS.CONTACT} className="underline">
								contact us
							</Link>
							.
						</Copy>
					</PageSection>
				</>
			)}
		</Page>
	);
};

export const pageQuery = graphql`
	query {
		localSearchProducts {
			store
			index
		}
		categories: allPrismicProductCategory {
			edges {
				node {
					uid
					type
					data {
						title
						image {
							gatsbyImageData
						}
					}
				}
			}
		}
		groups: allPrismicProductGroup {
			edges {
				node {
					uid
					type
					data {
						title
						image {
							gatsbyImageData
						}
						categorisation {
							document {
								... on PrismicProductCategory {
									uid
									data {
										title
									}
									type
								}
							}
						}
					}
				}
			}
		}
		products: allPrismicProduct {
			edges {
				node {
					type
					data {
						title
						summary {
							html
						}
						product_image {
							gatsbyImageData
						}
						categorisation {
							document {
								... on PrismicProductCategory {
									uid
									data {
										title
									}
									type
								}
								... on PrismicProductGroup {
									uid
									data {
										categorisation {
											document {
												... on PrismicProductCategory {
													data {
														title
													}
													type
													uid
												}
											}
										}
										title
									}
									type
								}
							}
						}
					}
					uid
				}
			}
		}
		fallbackImage: file(relativePath: { eq: "shared/uhv-fallback.png" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;

export default ProductSearchPage;
